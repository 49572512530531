import React, { Component } from 'react';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

export class MyNavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  close = () => {
    this.setState({
      isOpen: false
    });
  }

  render() {
    return (
      <header>
        <Navbar color="light" light expand="lg" fixed='top'>
          <Container>
            <NavbarBrand href="./">KeySalt</NavbarBrand>
            <NavbarToggler onClick={() => this.toggle()} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink href="./#home" onClick={() => this.close()}>HOME</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="./#strengths" onClick={() => this.close()}>STRENGTHS</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="./#instructions" onClick={() => this.close()}>INSTRUCTIONS</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="./#fornerds" onClick={() => this.close()}>FOR NERDS</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="./#thanks" onClick={() => this.close()}>OTHERS</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
