import React, { Component } from "react";

import { MyNavBar } from './components/MyNavBar';
import { Home } from './components/Home';
import { Demo } from './components/Demo';
import { Strengths} from './components/Strengths';
import { Instructions } from './components/Instructions';
import { ForNerds } from './components/ForNerds';
import { Thanks } from './components/Thanks';
import { PayForThisProgram } from './components/PayForThisProgram';
import { Disclaimer } from './components/Disclaimer';

import './App.css';

export class App extends Component {
  render() {
    return (
      <div>
        <MyNavBar />
        <Home />
        <Demo />
        <Strengths />
        <Instructions />
        <ForNerds />
        <Thanks />
        <PayForThisProgram />
        <Disclaimer />
      </div>
    );
  }
}
