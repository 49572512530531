/* global BigInt */

import forge from 'node-forge';

export function checkKey(key) {
  return key.length > 0;
}

export function checkSalt(salt) {
  return salt.match(/^(0[4-9]|1[0-9]|20)[A-E][A-Za-z0-9]+$/g) !== null;
}

function sha256(s) {
  let md = forge.md.sha256.create();
  md.update(s, 'utf8');
  return md.digest().toHex();
}

export function getPassword(key, salt) {
  if (!checkKey(key) || !checkSalt(salt)) {
    return 'N/A';
  }

  const sNumber = "0123456789";
  const sLowercase = "abcdefghijklmnopqrstuvwxyz";
  const sUppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const sSpecial = "!#$%&'()*+,-./:;<=>?@[\\]^_{}~";

  const characterSetAll = {};
  characterSetAll["A"] = sNumber;
  characterSetAll["B"] = sLowercase;
  characterSetAll["C"] = sNumber + sNumber + sNumber + sLowercase;
  characterSetAll["D"] = sNumber + sNumber + sNumber + sLowercase + sUppercase;
  characterSetAll["E"] = sNumber + sNumber + sNumber + sLowercase + sUppercase + sSpecial;

  const hashString = sha256(sha256(key) + salt);

  const passwordLength = parseInt(salt.slice(0, 2));
  const oneGroupLength = Math.floor(64 / passwordLength);
  const passwordType = salt[2];
  const characterSet = characterSetAll[passwordType];

  let password = "";
  for (let i = 0; i < passwordLength; i++) {
    const beginPosition = i * oneGroupLength;
    const endPosition = (i + 1) * oneGroupLength;
    const value = BigInt("0x" + hashString.slice(beginPosition, endPosition));
    password += characterSet[value % BigInt(characterSet.length)];
  }

  return password;
}
