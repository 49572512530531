import React, { Component } from 'react';
import {
  Container, Row, Col,
  Button,
  Jumbotron,
  Collapse,
  CustomInput,
  Form, FormGroup, Label, Input, FormFeedback
} from 'reactstrap';

import { checkKey, checkSalt, getPassword } from '../utils/algorithm';

export class Demo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDemoOpen: false,
      showKey: false,
      key: '',
      isKeyValid: false,
      salt: '',
      isSaltValid: false,
      password: 'N/A',
    };
  }

  toggleIsDemoOpen = () => {
    this.setState({
      ...this.state,
      isDemoOpen: !this.state.isDemoOpen
    });
  }

  toggleShowKey = () => {
    this.setState({
      ...this.state,
      showKey: !this.state.showKey
    });
  }

  onKeyChange = (event) => {
    const key = event.target.value;
    this.setState({
      ...this.state,
      key,
      isKeyValid: checkKey(key),
      password: getPassword(key, this.state.salt)
    })
  }

  onSaltChange = (event) => {
    const salt = event.target.value;

    this.setState({
      ...this.state,
      salt,
      isSaltValid: checkSalt(salt),
      password: getPassword(this.state.key, salt)
    })
  }

  render() {
    return (
      <>
        <Collapse isOpen={!this.state.isDemoOpen}>
          <Container id="demo-button" className='anachor'>
            <Row>
              <Col>
                <Button
                  onClick={this.toggleIsDemoOpen}
                  block
                >
                  Try it now!
                </Button>
              </Col>
            </Row>
          </Container>
        </Collapse>
        <Collapse isOpen={this.state.isDemoOpen}>
          <Container id="demo" className='anachor'>
            <Row>
              <Col>
                <Button
                  onClick={this.toggleIsDemoOpen}
                  close
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Jumbotron style={{ background: 'white' }}>
                  <Form>
                    <FormGroup row style={{ height: 120 }}>
                      <Label xs={2}>Key</Label>
                      <Col xs={10}>
                        <Input
                          type={this.state.showKey ? 'text' : 'password'}
                          valid={this.state.isKeyValid}
                          invalid={!this.state.isKeyValid}
                          onChange={this.onKeyChange}
                          value={this.state.key}
                          placeholder="Example: dog is flying like cat"
                        />
                        <FormFeedback valid>
                          <CustomInput
                            type="switch"
                            checked={this.state.showKey}
                            id="showKeySwitch"
                            onChange={this.toggleShowKey}
                          />
                        </FormFeedback>
                        <FormFeedback valid={false}>Key should be non-empty.</FormFeedback>
                      </Col>
                    </FormGroup>
                    <FormGroup row style={{ height: 120 }}>
                      <Label xs={2}>Salt</Label>
                      <Col xs={10}>
                        <Input
                          valid={this.state.isSaltValid}
                          invalid={!this.state.isSaltValid}
                          onChange={this.onSaltChange}
                          value={this.state.salt}
                          placeholder="Example: 12Emygmail"
                        />
                        <FormFeedback valid={false}>
                          Salt should match the format: length of password (04 - 20) + type of password (A - E) + a non-empty note.
                          <br/>
                          e.g. 06Abankpin, 08Emygmail, 12Bkey.
                        </FormFeedback>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label xs={4}>Password</Label>
                      <Label xs={8} style={{ fontFamily: "Source Code Pro" }}>{this.state.password}</Label>
                    </FormGroup>
                  </Form>
                </Jumbotron>
              </Col>
            </Row>
          </Container>
        </Collapse>
        <hr />
      </>
    );
  }
}
