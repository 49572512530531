import React from 'react';
import {
  Container, Row, Col,
  Jumbotron,
} from 'reactstrap';

export const Instructions = () => {
  return (
    <>
      <Container id="instructions" className='anchor'>
        <Row>
          <Col>
            <Jumbotron style={{ background: 'white' }}>
              <h3>Instructions（指南）</h3>
              <div className="mt-4">
                A brief instruction is shown as below:
              </div>
              <ol>
                <li>
                  Password is calculated based on key and salt. For a pair of given key and salt,
                  the calculated password is also determined (not randomly generated).
                </li>
                <li>
                  Key is a secret sentence, which is set by you. This sentence should be complex enough.
                  This sentence should be kept as a secret. Do not write it in your office notebook or
                  email. (Of course, our app will never collect or store your key.)
                </li>
                <li>Salt is a word contains the specification for one password you want to generate.
                You can specify the length and type of your password in salt.
                It is not necessary to keep your salt in high security as key.
                All salts can be stored in this app and backed up by in your notebook.
                </li>
                <li>
                  According to our algorithm, it is secure to use one key (and multiple salts)
                  for multiple passwords. In this way, you just need to memorize one complex sentence (key)
                  to manage all of your passwords.
                </li>
              </ol>
              <div>
                Detailed instructions are already put at appropriate places in the app. Of course,
                they can also be accessed by clicking the following links:
              </div>
              <ul>
                <li>Main instruction</li>
                <li>Key instruction</li>
                <li>Salts Table instruction</li>
                <li>Salt instruction</li>
              </ul>
              <div className="mt-3">
                简要指南如下所示：
              </div>
              <ol>
                <li>密码是由key和salt计算而来。对于一对确定的key和salt，计算得到的密码也是确定的（非随机生成）。</li>
                <li>
                  key是一个秘密的句子，需要由你来设置。这个句子需要足够复杂。这个句子需要保密。
                  不要将这个句子写到你的办公室笔记本或者email里。（当然，本app永远不会收集或者存储你的key。）
                </li>
                <li>
                  salt是一个用来对你想要生成的某个密码进行具体说明的短语。你可以在salt中指明你的密码长度和类型。
                  salt不需要像key那样高度保密。所有的salt都可以存储在此app里并备份到你的笔记本中。
                </li>
                <li>
                  根据我们的算法，使用一个key（和多个salt）来产生多个密码是安全的。
                  这样，你仅仅需要记住一个复杂的句子（key）就可以管理你的所有密码。
                </li>
              </ol>
              <div>
                详细的指南已经被放置在app的适当位置。当然，你也可以通过点击下面的链接来查看：
              </div>
              <ul>
                <li>主指南</li>
                <li>Key指南</li>
                <li>Salts表格指南</li>
                <li>Salt指南</li>
              </ul>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
      <hr />
    </>
  );
}
