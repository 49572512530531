import React from 'react';
import {
  Container, Row, Col,
  Jumbotron,
} from 'reactstrap';

export const Disclaimer = () => {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <Jumbotron style={{ background: 'white' }}>
              <h3>Disclaimer（免责声明）</h3>
              <div>
                User agrees that all risks related to the use of this software will be undertaken entirely by the user.
                The author (Lei Zhang) is not responsible for any loss that may occur. Here "this software" includes
                iOS app (any version), python code and web version.
              </div>
              <div>
                用户同意其使用本软件所存在的风险将完全由用户本人承担。
                作者（章雷）对可能发生的损失不承担任何责任。这里所说的“本软件”包括iOS app（任意版本），python代码，网页版本。
              </div>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
      <hr />
    </>
  );
}
