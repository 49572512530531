import React from 'react';
import {
  Container, Row, Col,
  Jumbotron,
} from 'reactstrap';

export const Thanks = () => {
  return (
    <>
      <Container id="thanks" className='anchor'>
        <Row>
          <Col>
            <Jumbotron style={{ background: 'white' }}>
              <h3>Thanks（感谢）</h3>
              <ul className="mt-4">
                <li>Thank my friends for offering help and tolerating my endless questions.</li>
                <li>The iOS app icon is from https://pixabay.com/en/background-brushstroke-color-1366924/.</li>
              </ul>
              <ul className="mt-3">
                <li>感谢我的朋友们为我提供帮助并忍受我无穷无尽的问题。</li>
                <li>iOS app图标来源于https://pixabay.com/en/background-brushstroke-color-1366924/。</li>
              </ul>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
      <hr />
    </>
  );
}
