import React from 'react';
import {
  Container, Row, Col,
  Jumbotron,
} from 'reactstrap';

export const Strengths = () => {
  return (
    <>
      <Container id="strengths" className='anchor'>
        <Row>
          <Col>
            <Jumbotron style={{ background: 'white' }}>
              <h3>Strengths（优势）</h3>
              <ul className="mt-4">
                <li>You just need to memorize one sentence to securely manage all your passwords.</li>
                <li>The app works offline and does not store any private information of you.</li>
                <li>Five types (e.g., only contains numbers, only contains letters) of passwords can be generated.</li>
                <li>You can have one independent complex password for every of your account.</li>
                <li>Even if some passwords are leaked, all other passwords are still in secure.</li>
                <li>The algorithm is open sourced.</li>
                <li>Of course, the app is free (at least now) and has no In-App Purchase.</li>
              </ul>
              <ul className="mt-3">
                <li>你只需要记住一个句子就可以安全地管理你所有的密码。</li>
                <li>无需联网即可使用，不存储任何个人隐私信息。</li>
                <li>可以用来生成五种类型（例如：仅含数字，仅含小写字母）密码。</li>
                <li>你可以方便地为你的每一个账户生成一个独立的复杂密码。</li>
                <li>即使一些密码泄露了，其他所有密码也仍然安全。</li>
                <li>算法是开源的。</li>
                <li>当然，这个app（至少现在）是免费的，并且没有任何应用内购买。</li>
              </ul>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
      <hr />
    </>
  );
}
