import React from 'react';
import {
  Container, Row, Col,
  Jumbotron,
} from 'reactstrap';

import downloadOnAppStore from '../images/download_on_app_store.svg';

export const Home = () => {
  return (
    <>
      <Container className="mt-5 mb-n5 anchor" id="home">
        <Row>
          <Col xs={12} md={5}>
            <Jumbotron style={{ background: 'white' }}>
              <div>
                <h1 className='mb-3'>KeySalt</h1>
                <a href="https://apps.apple.com/app/password-by-lei-zhang/id1248596510">
                  <img src={downloadOnAppStore} alt='Download on App Store' width={200} />
                </a>
                <h4 className='mt-5'>Designed by Lei Zhang</h4>
              </div>
            </Jumbotron>
          </Col>
          <Col xs={12} md={7}>
            <Jumbotron style={{ background: 'white' }}>
              <div>
                KeySalt app is an elegant and powerful password management software.
                It uses an algorithm based on SHA256 to protect your passwords.
                Besides, it is amazingly easy to use.
                <br />
                Frankly speaking, it will take you about half an hour to understand how to use it.
                But after that, you do not need to worry about your passwords in your lifetime.
              </div>
              <div className="mt-3">
                这是一款优雅且强大的app。它采用了基于SHA256的算法确保您密码的安全性。另外，它好用到不行。
                <br />
                坦诚地说，您大概需要花半个小时才能理解如何使用它。但是在那之后，您这一生再也不需要为密码问题而发愁了。
              </div>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
      <hr />
    </>

  );
}
