import React from 'react';
import {
  Container, Row, Col,
  Jumbotron,
} from 'reactstrap';

export const PayForThisProgram = () => {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <Jumbotron style={{ background: 'white' }}>
              <h3>Pay for this program（为本项目付费）</h3>
              <div>
                If you do think this is a great program, please spend some time sharing it with your friends.
                We do not have any plan to charge now.
              </div>
              <div>
                如果你真的认为这是一个好的项目，请花一点时间把它分享给你的朋友们。
                目前我们没有任何收费计划。
              </div>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
      <hr />
    </>
  );
}
