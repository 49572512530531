import React from 'react';
import {
  Container, Row, Col,
  Jumbotron,
} from 'reactstrap';

export const ForNerds = () => {
  return (
    <>
      <Container id="fornerds" className='anchor'>
        <Row>
          <Col>
            <Jumbotron style={{ background: 'white' }}>
              <h3>For Nerds</h3>
              <div className="mt-4">
                The password generation algorithm has been open-sourced.
                A Python3 version is already put in Github.
                The repository link is https://github.com/leikdga/keysalt-algorithm.
              </div>
              <div>
                About the key verification functionality in iOS app, when the user decides to update stored keyhash,
                the app will generate a string contains 100 random characters. The app will then store the random
                string and first 6 hex digits of sha256(key + random_string). When user types key next time,
                to verify whether typed sentence is the right key, the app just needs to compare first 6 hex digits
                of sha256(input + random_string) and the stored hex digits.
              </div>
              <div>
                If you have any questions about this program or find a bug, please contact with me.
                My email is leizhanghello AT gmail.com.
              </div>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
      <hr />
    </>
  );
}
